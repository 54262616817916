const addOneDay = input => {
  // Convert the input string to a Date object
  const date = new Date(input);

  // Add one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

  // Convert the updated Date object back to a string in the desired format
  const output = date.toISOString();

  return output;
};

export default {
  getClassroomsBegin(state) {
    state.loading = true;
  },

  getClassroomsBeginSuccess(state, classrooms) {
    state.loading = false;

    console.log({ classrooms });

    try {
      classrooms = classrooms.map(classroom => {
        classroom.studentInfos = classroom.studentInfos.map(student => {
          const dateOfBirth = addOneDay(student.dateOfBirth);
          const joinedAt = addOneDay(student.joinedAt);
          student.dateOfBirth = dateOfBirth;
          student.joinedAt = joinedAt;
          return student;
        });

        return classroom;
      });

      state.classrooms = classrooms;
    } catch (error) {
      console.log({ error });
    }
  },

  createClassroomsBegin(state) {
    state.loading = true;
  },

  createClassroomsBeginSuccess(state, classroom) {
    state.loading = false;
    state.classrooms.push(classroom);
  },

  removeClassroomsBeginSuccess(state, classroomId) {
    state.loading = false;
    state.classrooms = state.classrooms.filter(classroom => classroom.id != classroomId);
  },

  actionClassroomError(state, error) {
    state.loading = false;
    state.error = error;
  },

  singleSchoolYearBegin(state) {
    state.loading = true;
  },

  singleSchoolYearSuccess(state, data) {
    state.loading = false;
    state.singleData = data;
  },

  singleSchoolYearErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  filterSchoolYearBegin(state) {
    state.loading = true;
  },

  filterSchoolYearSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  filterSchoolYearErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sortingSchoolYearBegin(state) {
    state.loading = true;
  },

  sortingSchoolYearSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  sortingSchoolYearErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  updateEditingClassroom(state, { isShow, classroom }) {
    state.onShowEditClassrooom = isShow;
    state.editingClassroom = classroom;
  },
};
