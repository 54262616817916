export default {
  fetchingBegin(state) {
    state.loading = true;
  },
  fetchingError(state, err) {
    state.loading = true;
    state.error = err;
  },
  getDevelopmentReportSuccess(state, data) {
    state.developmentReport = data;
  },
  getAttendanceReportSuccess(state, data) {
    state.attendanceReport = data;
  },
};
