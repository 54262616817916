import staticData from '@/demoData/classroom.json';
import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';

const state = () => ({
  data: staticData,
  classrooms: null,
  singData: staticData,
  loading: false,
  error: null,
  onShowEditClassrooom: false,
  editingClassroom: null,
});

const getters = {
  getClassroomById: state => classroomId => {
    return state.classrooms.find(classroom => {
      return classroom.id == classroomId;
    });
  },
};

const actions = {
  onChangeEditingClassroom({ commit }, { isShow, classroom }) {
    commit('updateEditingClassroom', { isShow, classroom });
  },

  async getClassrooms({ commit }, { schoolYearId, callback }) {
    try {
      commit('getClassroomsBegin');
      const { data } = await DataService.get(`/calendars/${schoolYearId}/classrooms`);
      const classrooms = data;
      console.log({ data: classrooms });
      commit('getClassroomsBeginSuccess', classrooms);
      callback(classrooms);
    } catch (error) {
      commit('actionClassroomError', error);
    }
  },

  async createClassroom({ commit }, { classroom, schoolYearId, callback }) {
    try {
      commit('createClassroomsBegin');
      const { data } = await DataService.post(`/calendars/${schoolYearId}/classrooms`, classroom);
      console.log({ data: data });
      commit('createClassroomsBeginSuccess', data);
      console.log({ callback });
      callback(data);
    } catch (error) {
      console.error(error);
      commit('actionClassroomError', error);
    }
  },

  getAllClassrooms({ commit }, { schoolYearId }) {
    return new Promise((resolve, reject) => {
      commit('getClassroomsBegin');
      DataService.get(`/calendars/${schoolYearId}/classrooms`)
        .then(response => {
          const { data: classrooms } = response;
          commit('getClassroomsBeginSuccess', classrooms);
          resolve(classrooms);
        })
        .catch(err => {
          commit('actionClassroomError', err);
          reject(err);
        });
    });
  },

  forceGetAllClassrooms({ commit }, { schoolYearId }) {
    return new Promise((resolve, reject) => {
      commit('getClassroomsBegin');
      DataService.get(`/calendars/${schoolYearId}/classrooms`)
        .then(response => {
          const { data: classrooms } = response;
          commit('getClassroomsBeginSuccess', classrooms);
          resolve(classrooms);
        })
        .catch(err => {
          commit('actionClassroomError', err);
          reject(err);
        });
    });
  },

  async removeClassroom({ commit }, { classroomId, schoolYearId, callback }) {
    try {
      commit('removeClassroomsBegin');
      const { data } = await DataService.delete(`/calendars/${schoolYearId}/classrooms/${classroomId}`);
      console.log({ data: data });
      commit('removeClassroomsBeginSuccess', classroomId);
      callback(data);
    } catch (error) {
      commit('actionClassroomError', error);
    }
  },

  async filterSinglePage({ commit }, paramsId) {
    try {
      commit('singleSchoolYearBegin');
      const data = staticData.filter(schoolYear => {
        return schoolYear.id === parseInt(paramsId, 10);
      });
      commit('singleSchoolYearSuccess', data);
    } catch (err) {
      commit('singleSchoolYearErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
