import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import { notification } from 'ant-design-vue';
import { storage } from '@/configs/database/firebase';

const updateNotificationSuccess = () => {
  notification.success({
    message: 'บันทึกข้อมูลโรงเรียนเรียบร้อยแล้ว',
  });
};

const updateNotificationError = err => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  infos: null,
  loading: false,
  error: null,
});

const actions = {
  schoolInfoUpdate({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('schoolInfoUpdateBegin');
      DataService.put(`/schools/infos`, data)
        .then(() => {
          commit('schoolInfoUpdateSuccess');
          updateNotificationSuccess();
          resolve();
        })
        .catch(err => {
          commit('schoolInfoUpdateErr', err);
          updateNotificationError(err);
          reject(err);
        });
    });
  },
  async schoolInfoGetData({ commit }) {
    return new Promise((resolve, reject) => {
      commit('schoolInfoReadBegin');
      DataService.get('/schools/infos')
        .then(response => {
          const { data } = response;
          commit('schoolInfoReadSuccess', data);
          resolve(data);
        })
        .catch(error => {
          commit('schoolInfoReadErr', error);
          reject(error);
        });
    });
  },
  async forceSchoolInfoGetData({ commit }) {
    return new Promise((resolve, reject) => {
      commit('schoolInfoReadBegin');
      DataService.get('/schools/infos')
        .then(response => {
          const { data } = response;
          commit('schoolInfoReadSuccess', data);
          resolve(data);
        })
        .catch(error => {
          commit('schoolInfoReadErr', error);
          reject(error);
        });
    });
  },
  uploadSchoolLogo(_, { schoolId, file }) {
    return new Promise((resolve, reject) => {
      const ref = storage.ref(`school_logos/sch_${schoolId}`);
      const uploadTask = ref.put(file);
      const onStateChanged = () => {};
      const onError = error => {
        reject(error);
      };
      const onSuccess = () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
          resolve(downloadUrl);
        });
      };
      uploadTask.on('state_changed', onStateChanged, onError, onSuccess);
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
