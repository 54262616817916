<template>
  <sdModal :type="modalType" title="สร้างห้องเรียนใหม่" :visible="visible" :onCancel="handleCancel">
    <div class="project-modal">
      <BasicFormWrapper>
        <a-form :model="formState">
          <a-form-item name="title" label="ชื่อห้องเรียน">
            <a-input v-model:value="formState.title" placeholder="ชื่อห้องเรียน เช่น ห้องทานตะวัน" />
          </a-form-item>

          <a-form-item name="roomNo" label="ลำดับห้อง">
            <a-input-number
              disabled="true"
              v-model:value="formState.roomNo"
              placeholder="ลำดับห้อง เช่น 1 หรือ 2 หรือ 3 เป็นต้น"
            />
          </a-form-item>

          <a-form-item name="gradeLevel" label="ระดับชั้น">
            <a-select v-model:value="formState.gradeLevel" style="width: 100%" disabled="true">
              <a-select-option :key="level.value" v-for="level in classroomLevels" :value="level.value">{{
                level.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-row :gutter="15">
            <a-col :md="12">
              <a-form-item>
                <ProjectModalFooter>
                  <sdButton size="default" type="primary" key="submit" @click="handleOk">
                    แก้ไข
                  </sdButton>
                  <sdButton size="default" type="light" key="back" outlined @click="handleCancel">
                    ยกเลิก
                  </sdButton>
                </ProjectModalFooter>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </BasicFormWrapper>
    </div>
  </sdModal>
</template>
<script>
import propTypes from 'vue-types';
import { BasicFormWrapper } from '../../../styled';
import { ProjectModalFooter } from '../style';
import { computed, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import locale from 'ant-design-vue/es/date-picker/locale/th_TH';
import { notification } from 'ant-design-vue';

const options = [
  {
    label: 'Privet',
    value: 'privet',
  },
  {
    label: 'Team',
    value: 'team',
  },
  {
    label: 'Public',
    value: 'public',
  },
];

const dateFormat = 'DD MMM YYYY';

const EditClassroom = {
  name: 'EditClassroom',
  components: { BasicFormWrapper, ProjectModalFooter },
  props: {
    visible: propTypes.bool.def(false),
    onCancel: propTypes.func.isRequired,
    onSuccess: propTypes.func.isRequired,
    onLoading: propTypes.func.isRequired,
    classroom: Object,
  },
  setup(props) {
    const modalType = ref('primary');
    const checked = ref([]);
    const { onCancel, onSuccess, onLoading } = toRefs(props);

    const { state, dispatch } = useStore();
    const classroomLevels = computed(() => state.appconstant.classroomLevel);
    const schoolYear = computed(() => state.schoolYear.selected);

    const formState = reactive({
      title: undefined,
      roomNo: undefined,
      id: undefined,
      gradeLevel: undefined,
      gradeLevelTitle: undefined,
    });

    watch(
      () => state.classroom.editingClassroom,
      editClassroomInfo => {
        if (!editClassroomInfo) {
          return;
        }
        formState.title = editClassroomInfo.title;
        formState.roomNo = editClassroomInfo.roomNo;
        formState.gradeLevel = editClassroomInfo.gradeLevel;
        formState.id = editClassroomInfo.id;
      },
    );

    const handleOk = () => {
      if (formState.title.length == 0) {
        notification.warning({
          message: 'กรุณากรอกชื่อห้อง',
        });
        return;
      }
      if (formState.roomNo.length == 0) {
        notification.warning({
          message: 'กรุณากรอกเลขห้อง',
        });
        return;
      }

      if (formState.gradeLevelTitle.length == 0) {
        notification.warning({
          message: 'กรุณาเลือกระดับชั้น',
        });
        return;
      }
      onLoading.value();
      formState.gradeLevelTitle = undefined;
      dispatch('createClassroom', {
        classroom: formState,
        schoolYearId: schoolYear.value.id,
        callback: () => {
          formState.title = undefined;
          formState.roomNo = undefined;
          formState.gradeLevel = classroomLevels.value[0].value;
          onSuccess.value();
        },
      });
    };

    const handleCancel = () => {
      formState.title = undefined;
      formState.roomNo = undefined;
      formState.gradeLevel = classroomLevels.value[0].value;
      onCancel.value();
    };

    return {
      options,
      handleOk,
      handleCancel,
      modalType,
      checked,
      dateFormat,
      formState,
      locale,
      classroomLevels,
      dispatch,
      onSuccess,
      onLoading,
    };
  },
};

export default EditClassroom;
</script>
