<template>
  <sdModal :type="modalType" title="สร้างปีการศึกษาใหม่" :visible="visible" :onCancel="handleCancel">
    <a-spin tip="กำลังสร้างปีการศึกษา..." :spinning="loading">
      <div class="project-modal">
        <BasicFormWrapper>
          <a-form ref="newSchoolYearRef" :model="stateSchoolYear" @finish="handleOk" :rules="schoolYearRules">
            <a-form-item name="year" label="เลขปีการศึกษา">
              <a-input v-model:value="stateSchoolYear.year" placeholder="ตัวอย่าง เช่น 2565" />
            </a-form-item>

            <a-form-item name="firstTermRange" label="ภาคเรียนที่ 1 (ไม่รวมปิดเทอม)">
              <a-range-picker v-model:value="stateSchoolYear.firstTermRange" :locale="locale" :format="dateFormat" />
            </a-form-item>

            <a-form-item name="secondTermRange" label="ภาคเรียนที่ 2 (ไม่รวมปิดเทอม)">
              <a-range-picker v-model:value="stateSchoolYear.secondTermRange" :locale="locale" :format="dateFormat" />
            </a-form-item>

            <a-form-item name="standardYear" label="มาตรฐานการประเมินตามปี">
              <a-select v-model:value="stateSchoolYear.standardYear" :style="{ width: '100%' }">
                <template v-for="year in standardYears" :key="`std${year}`">
                  <a-select-option :value="parseInt(year)">พ.ศ. {{ year }}</a-select-option>
                </template>
              </a-select>
            </a-form-item>

            <a-row :gutter="15">
              <a-col :md="12">
                <a-form-item>
                  <ProjectModalFooter>
                    <sdButton size="default" type="primary" key="submit" :load="true">
                      สร้างปีการศึกษาใหม่
                    </sdButton>
                    <sdButton size="default" type="light" key="back" outlined @click="handleCancel">
                      ยกเลิก
                    </sdButton>
                  </ProjectModalFooter>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </BasicFormWrapper>
      </div>
    </a-spin>
  </sdModal>
</template>
<script>
import propTypes from 'vue-types';
import { BasicFormWrapper } from '../../../styled';
import { ProjectModalFooter } from '../style';
import { reactive, ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';

import locale from 'ant-design-vue/es/date-picker/locale/th_TH';

const schoolYearRules = {
  year: [
    {
      required: true,
      message: 'กรุณาระบุเลขปีการศึกษา',
      trigger: 'blur',
    },
    {
      pattern: /\d{4}/,
      message: 'กรุณาระบุเป็นตัวเลขเท่านั้น',
      trigger: 'change',
    },
  ],
  standardYear: [
    {
      required: true,
      message: 'กรุณาเลือกมาตรฐานการประเมินพัฒนาการ',
      trigger: 'change',
      type: 'number',
    },
  ],
  firstTermRange: [
    {
      type: 'array',
      required: true,
      message: 'กรุณาระบุวันที่เริ่มและสิ้นสุด',
      trigger: 'change',
    },
  ],
  secondTermRange: [
    {
      type: 'array',
      required: true,
      message: 'กรุณาระบุวันที่เริ่มและสิ้นสุด',
      trigger: 'change',
    },
  ],
};

const dateFormat = 'DD MMM YYYY';

const CreateSchoolYear = {
  name: 'CreateSchoolYear',
  components: { BasicFormWrapper, ProjectModalFooter },
  props: {
    visible: propTypes.bool.def(false),
    onCancel: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
  },
  setup(props) {
    const modalType = ref('primary');
    const checked = ref([]);
    const newSchoolYearRef = ref(null);
    const { onCancel, onSubmit } = toRefs(props);
    const { state } = useStore();
    const standardYears = computed(() => state.evaluateForm.standardYears);
    const loading = computed(() => state.schoolYear.loading);

    const stateSchoolYear = reactive({
      year: null,
      firstTermRange: [],
      secondTermRange: [],
      standardYear: 2560,
    });

    const preparedCalendar = () => {
      const { year, firstTermRange, secondTermRange, standardYear } = stateSchoolYear;
      const firstTerm = {
        termNo: 1,
        startDate: firstTermRange[0],
        endDate: firstTermRange[1],
      };
      const secondTerm = {
        termNo: 2,
        startDate: secondTermRange[0],
        endDate: secondTermRange[1],
      };
      return {
        year,
        standardYear: parseInt(standardYear),
        terms: [firstTerm, secondTerm],
      };
    };

    const handleOk = async () => {
      const validated = await newSchoolYearRef.value.validate();
      if (!validated) return;
      onSubmit.value(preparedCalendar());
    };

    const handleCancel = () => {
      onCancel.value();
    };

    return {
      handleOk,
      handleCancel,
      modalType,
      checked,
      dateFormat,
      stateSchoolYear,
      standardYears,
      schoolYearRules,
      newSchoolYearRef,
      locale,
      loading,
    };
  },
};

export default CreateSchoolYear;
</script>
