export default [
  {
    path: '/print/schoolyear/:schoolYearId/classroom/:classroomId',
    name: 'print-classroom-development',
    props: true,
    component: () => import(/* webpackChunkName: "classroom" */ '@/view/pages/print/Classroom.vue'),
  },
  {
    path: '/print/attendances',
    name: 'print-attendances',
    component: () => import(/* webpackChunkName: "attendances" */ '@/view/pages/print/Attendances.vue'),
  },
  {
    path: '/print/schoolyear/:schoolYearId/student/:studentId',
    name: 'print-student-development',
    props: true,
    component: () => import(/* webpackChunkName: "student" */ '@/view/pages/print/Student.vue'),
  },
];
