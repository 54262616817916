const routes = [
  {
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/FbSignIn.vue'),
  },
  {
    path: 'register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/view/authentication/FbSignup.vue'),
  },
  // {
  //   path: 'forgotPassword',
  //   name: 'forgotPassword',
  //   component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ForgotPassword.vue'),
  // },
  {
    path: 'line',
    name: 'line',
    props: route => {
      return { query: route.query };
    },
    component: () => import(/* webpackChunkName: "line" */ '@/view/authentication/LineSignup.vue'),
  },
  {
    path: 'line-callback',
    name: 'line-callback',
    meta: { line: true },
    props: route => {
      return { query: route.query };
    },
    component: () => import(/* webpackChunkName: "line-callback" */ '@/view/authentication/LineCallback.vue'),
  },
];

export default routes;
