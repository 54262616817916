import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import { notification } from 'ant-design-vue';

const state = () => ({
  currentRecords: [],
  selected: null,
  loading: false,
  error: null,
});

const getters = {};

const updateNotificationSuccess = message => {
  notification.success({ message });
};

const updateNotificationError = err => {
  notification.error({
    message: err,
  });
};

const actions = {
  getClassroomBodymassRecords({ commit }, { schoolYearId, classroomId, query }) {
    return new Promise((resolve, reject) => {
      commit('getBodymassRecordBegin');
      DataService.get(`/calendars/${schoolYearId}/classrooms/${classroomId}/bodymass-records`, query)
        .then(response => {
          const { data } = response;
          commit('getBodymassRecordSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getBodymassRecordErr', err);
          updateNotificationError(err);
          reject(err);
        });
    });
  },

  updateClassroomBodymassRecords({ commit }, postData) {
    return new Promise((resolve, reject) => {
      commit('updateBodymassRecordBegin');
      DataService.post(`/calendars/${postData.schoolYearId}/classrooms/${postData.classroomId}/bodymass-records`, {
        schoolTermId: postData.schoolTermId,
        evaluateNo: postData.evaluateNo,
        records: postData.records,
      })
        .then(() => {
          commit('updateBodymassRecordSuccess');
          updateNotificationSuccess('บันทึกสัดส่วนนักเรียนเรียบร้อยแล้ว');
          resolve(true);
        })
        .catch(err => {
          commit('updateBodymassRecordErr', err);
          updateNotificationError(err);
          reject(err);
        });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
