const addOneDay = input => {
  // Convert the input string to a Date object
  const date = new Date(input);

  // Add one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

  // Convert the updated Date object back to a string in the desired format
  const output = date.toISOString();

  return output;
};

export default {
  getStudentsBegin(state) {
    state.loading = true;
  },
  getStudentsSuccess(state, students) {
    state.loading = false;
    const dateOfBirth = addOneDay(students.dateOfBirth);
    const joinedAt = addOneDay(students.joinedAt);
    students.dateOfBirth = dateOfBirth;
    students.joinedAt = joinedAt;
    state.students = students;
  },
  updateStudents(state, students) {
    state.loading = false;
    state.students = students;
  },

  createStudentBegin(state) {
    state.loading = true;
  },

  createStudentSuccess(state, student) {
    state.loading = false;
    state.students = state.students.add(student);
  },

  createBulkStudentBegin(state) {
    state.loading = true;
  },

  createBulkStudentSuccess(state) {
    state.loading = false;
  },

  updateStudentBegin(state) {
    state.loading = true;
  },

  updateStudentSuccess(state, student) {
    state.loading = false;
    state.students = state.students.map(function(item) {
      return item.id == student.id ? student : item;
    });
  },

  deleteStudentBegin(state) {
    state.loading = true;
  },

  deleteStudentSuccess(state, studentId) {
    state.loading = false;
    state.students = state.students.filter(student => {
      return student.id != studentId;
    });
  },

  getStudentInfoBegin(state) {
    state.loading = true;
  },

  getStudentInfoSuccess(state, student) {
    state.loading = false;
    state.selected = student;
  },

  actionStudentError(state, error) {
    state.loading = false;
    state.error = error;
  },

  updateStudentTable(state, classrooms) {
    state.loading = false;
    const students = classrooms.reduce((acc, classroom) => {
      acc = acc.concat(classroom.studentInfos);
      return acc;
    }, []);
    state.data = students;
  },
};
