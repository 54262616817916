// import staticData from '@/demoData/schoolYears.json'; // stutdents.json
import mutations from './mutations';

const state = () => ({
  classroomLevel: [
    { value: 'NURSERY', name: 'ปฐมวัย (อายุต่ำกว่า 3 ปี)' },
    { value: 'PRE_KINDER1', name: 'ปฐมวัย (อายุ 3 - 4 ปี)' },
    { value: 'PRE_KINDER2', name: 'ปฐมวัย (อายุ 4 - 5 ปี)' },
    { value: 'KINDERGARTEN', name: 'ปฐมวัย (อายุ 5 - 6 ปี)' },
  ],
  schoolYears: [2560],
});

export default {
  namespaced: false,
  state,
  mutations,
};
