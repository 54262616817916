export default [
  {
    name: 'home',
    path: '/',
    redirect: '/school/calendar/grid',
  },
  // {
  //   path: '/starter',
  //   name: 'starter',
  //   component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/BlankPage.vue'),
  // },
  // {
  //   path: '/page/testimonials',
  //   name: 'testimonials',
  //   component: () => import(/* webpackChunkName: "Testimonials" */ '@/view/pages/Testimonials.vue'),
  // },
  // {
  //   path: '/page/faqs',
  //   name: 'faq`s',
  //   component: () => import(/* webpackChunkName: "Faq" */ '@/view/pages/Faq.vue'),
  // },
  // {
  //   path: '/page/search',
  //   name: 'search',
  //   component: () => import(/* webpackChunkName: "Search" */ '@/view/pages/Search.vue'),
  // },
  // {
  //   path: '/page/gallery',
  //   name: 'gallery',
  //   component: () => import(/* webpackChunkName: "Gallery" */ '@/view/pages/Gallery.vue'),
  // },
  // {
  //   path: '/page/pricing',
  //   name: 'pricing',
  //   component: () => import(/* webpackChunkName: "PricingTable" */ '@/view/pages/PricingTable.vue'),
  // },
  // {
  //   path: '/page/banners',
  //   name: 'banners',
  //   component: () => import(/* webpackChunkName: "Banners" */ '@/view/pages/Banners.vue'),
  // },
  // {
  //   path: '/page/support',
  //   name: 'support',
  //   component: () => import(/* webpackChunkName: "support" */ '@/view/pages/Support.vue'),
  // },
  // {
  //   path: '/page/maintenance',
  //   name: 'maintenance',
  //   component: () => import(/* webpackChunkName: "maintenance" */ '@/view/pages/Maintenance.vue'),
  // },
  // {
  //   path: '/page/404',
  //   name: '404',
  //   component: () => import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
  // },
  // {
  //   path: '/page/comingSoon',
  //   name: 'comingSoon',
  //   component: () => import(/* webpackChunkName: "comingSoon" */ '@/view/pages/ComingSoon.vue'),
  // },
  // {
  //   path: '/:catchAll(.*)',
  //   component: () => import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
  // },
  // {
  //   path: '/page',
  //   name: 'settings',
  //   component: () => import(/* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'),
  //   children: [
  //     {
  //       path: 'profile-settings',
  //       name: 'profile-settings',
  //       component: () => import(/* webpackChunkName: "Profile" */ '@/view/pages/settings/overview/Profile.vue'),
  //     },
  //     {
  //       path: 'profile-settings/profile',
  //       name: 'set-profile',
  //       component: () => import(/* webpackChunkName: "Profile" */ '@/view/pages/settings/overview/Profile.vue'),
  //     },
  //     {
  //       path: 'profile-settings/account',
  //       name: 'set-account',
  //       component: () => import(/* webpackChunkName: "Account" */ '@/view/pages/settings/overview/Account.vue'),
  //     },
  //     {
  //       path: 'profile-settings/password',
  //       name: 'set-password',
  //       component: () => import(/* webpackChunkName: "Password" */ '@/view/pages/settings/overview/Password.vue'),
  //     },
  //     {
  //       path: 'profile-settings/social',
  //       name: 'set-social',
  //       component: () => import(/* webpackChunkName: "Social" */ '@/view/pages/settings/overview/SocialProfile.vue'),
  //     },
  //     {
  //       path: 'profile-settings/notification',
  //       name: 'set-notification',
  //       component: () =>
  //         import(/* webpackChunkName: "Notification" */ '@/view/pages/settings/overview/Notification.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/page/knowledgebase',
  //   name: 'knowledgebase',
  //   component: () => import(/* webpackChunkName: "knowledgebase" */ '@/view/pages/knowledgebase/MainBox.vue'),
  //   children: [
  //     {
  //       path: 'base',
  //       name: 'base',
  //       component: () => import(/* webpackChunkName: "base" */ '@/view/pages/knowledgebase/Index.vue'),
  //       children: [
  //         {
  //           path: 'plugins',
  //           name: 'kno-plugins',
  //           component: () =>
  //             import(/* webpackChunkName: "Plugins" */ '@/view/pages/knowledgebase/overview/ArticlePlugin.vue'),
  //         },
  //         {
  //           path: 'themes',
  //           name: 'kno-themes',
  //           component: () =>
  //             import(/* webpackChunkName: "Themes" */ '@/view/pages/knowledgebase/overview/ArticleTheme.vue'),
  //         },
  //         {
  //           path: 'extensions',
  //           name: 'kno-extensions',
  //           component: () =>
  //             import(/* webpackChunkName: "Extensions" */ '@/view/pages/knowledgebase/overview/ArticleExtension.vue'),
  //         },
  //       ],
  //     },
  //     {
  //       path: 'all-articles',
  //       name: 'all-articles',
  //       component: () => import(/* webpackChunkName: "all-articles" */ '@/view/pages/knowledgebase/AllArticle.vue'),
  //     },
  //     {
  //       path: 'single/1',
  //       name: 'knowledge-base-singale',
  //       component: () =>
  //         import(/* webpackChunkName: "knowledge-base" */ '@/view/pages/knowledgebase/SingleKnowledge.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/changelog',
  //   name: 'changelog',
  //   component: () => import(/* webpackChunkName: "changelog" */ '@/view/pages/Changelog.vue'),
  // },
  {
    name: 'school-infos',
    path: '/school/infos',
    component: () => import(/* webpackChunkName: "school-infos" */ '@/view/pages/schools/overview/SchoolInfos.vue'),
  },
  {
    name: 'school-years',
    path: '/school/calendar',
    component: () => import(/* webpackChunkName: "school-year" */ '@/view/pages/schoolYears/SchoolYears.vue'),
    children: [
      {
        path: 'grid',
        name: 'schoolYear-grid',
        components: {
          default: () => import(/* webpackChunkName: "school-year" */ '@/view/pages/schoolYears/SchoolYears.vue'),
          child: () => import(/* webpackChunkName: "grid" */ '@/view/pages/schoolYears/overview/Grid.vue'),
        },
      },
      {
        path: 'list',
        name: 'schoolYear-list',
        components: {
          default: () => import(/* webpackChunkName: "school-year" */ '@/view/pages/schoolYears/SchoolYears.vue'),
          child: () => import(/* webpackChunkName: "list" */ '@/view/pages/schoolYears/overview/List.vue'),
        },
      },
    ],
  },
  {
    name: 'school-years-details',
    path: '/school/calendar/:schoolYearId/details',
    props: true,
    component: () =>
      import(/* webpackChunkName: "school-year-details" */ '@/view/pages/schoolYears/SchoolYearDetails.vue'),
    children: [
      {
        path: 'activities',
        name: 'schoolyear-activities',
        components: {
          default: () =>
            import(/* webpackChunkName: "projectDetail" */ '@/view/pages/schoolYears/SchoolYearDetails.vue'),
          child: () => import(/* webpackChunkName: "activities" */ '@/view/pages/schoolYears/overview/Activities.vue'),
        },
      },
    ],
  },
  {
    name: 'school-years-edit',
    path: '/school/calendar/:schoolYearId/edit',
    props: true,
    component: () =>
      import(/* webpackChunkName: "school-year-details" */ '@/view/pages/schoolYears/EditSchoolYear.vue'),
  },
  {
    name: 'createSchoolYear',
    path: '/school/createCalendar',
    component: () => import(/* webpackChunkName: "createSchoolYear" */ '@/view/pages/schoolYears/CreateSchoolYear.vue'),
    children: [
      {
        path: 'grid',
        name: 'create-calendar-grid',
        components: {
          default: () => import(/* webpackChunkName: "project" */ '@/view/pages/schoolYears/CreateSchoolYear.vue'),
          child: () => import(/* webpackChunkName: "grid" */ '@/view/pages/schoolYears/overview/Grid.vue'),
        },
      },
    ],
  },
  {
    name: 'classroom',
    path: '/classroom',
    component: () => import(/* webpackChunkName: "createSchoolYear" */ '@/view/pages/classroom/Classroom.vue'),
    children: [
      {
        path: '',
        name: 'classroom-index',
        components: {
          default: () => import(/* webpackChunkName: "project" */ '@/view/pages/classroom/Classroom.vue'),
          child: () => import(/* webpackChunkName: "grid" */ '@/view/pages/classroom/overview/Grid.vue'),
        },
      },
    ],
  },
  {
    name: 'classroom-info',
    path: '/schoolYears/:schoolYearId/classrooms/:classroomId',
    props: true,
    redirect: { name: 'classroom-index-info' },
    component: () => import(/* webpackChunkName: "classroomInfo" */ '@/view/pages/classroomInfo/Index.vue'),
    children: [
      {
        path: '',
        name: 'classroom-index-info',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/pages/classroomInfo/UserListDataTable.vue'),
        },
      },
    ],
  },
  {
    name: 'classroom-evaluation',
    path: '/schoolYears/:schoolYearId/classrooms/:classroomId/evaluation',
    props: true,
    component: () =>
      import(/* webpackChunkName: "classroomEvaluation" */ '@/view/pages/evaluation/ClassroomEvaluation.vue'),
  },
  {
    name: 'schoolyear-evaluation',
    path: '/school/calendar/:schoolYearId/details/evaluation',
    props: true,
    component: () =>
      import(/* webpackChunkName: "classroomEvaluation" */ '@/view/pages/evaluationForm/ClassroomEvaluation.vue'),
  },
  {
    name: 'classroom-bodymass',
    path: '/schoolYears/:schoolYearId/classrooms/:classroomId/bodymass',
    props: true,
    component: () => import(/* webpackChunkName: "classroomBodymass" */ '@/view/pages/bodymass/ClassroomBodyMass.vue'),
  },
  {
    name: 'classroom-opinion',
    path: '/schoolYears/:schoolYearId/classrooms/:classroomId/opinion',
    props: true,
    component: () => import(/* webpackChunkName: "classroomOpinion" */ '@/view/pages/opinion/ClassroomOpinion.vue'),
  },
  {
    name: 'classroom-grade-confirmation',
    path: '/schoolYears/:schoolYearId/classrooms/:classroomId/grade-confirmation',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "classroomGradeConfirmation" */ '@/view/pages/gradeConfirmation/ClassroomGradeConfirmation.vue'
      ),
  },
  {
    name: 'import-student',
    path: '/classroom/:classroomId/import-student',
    props: true,
    component: () => import(/* webpackChunkName: "import-student" */ '../view/pages/classroomInfo/ImportStudents.vue'),
  },
  {
    name: 'create-student',
    path: '/schoolYears/:schoolYearId/classrooms/:classroomId/student/create',
    props: true,
    // component: () => import(/* webpackChunkName: "classroomInfo" */ '@/view/apps/users/AddUsers.vue'),
    component: () => import(/* webpackChunkName: "classroomInfo" */ '../view/pages/classroomInfo/AddUsers.vue'),
    children: [
      {
        path: '',
        name: 'student-',
        props: true,
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          descendant: () => import(/* webpackChunkName: "info" */ '../view/pages/classroomInfo/overview/Info.vue'),
        },
      },
      {
        path: 'info',
        name: 'create-student-info',
        props: true,
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          descendant: () => import(/* webpackChunkName: "info" */ '../view/pages/classroomInfo/overview/Info.vue'),
        },
      },
      {
        path: 'work',
        name: 'student-work',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          descendant: () => import(/* webpackChunkName: "work" */ '../view/pages/classroomInfo/overview/Work.vue'),
        },
      },
      {
        path: 'social',
        name: 'create-student-social-user',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          // descendant: () =>
          //   import(/* webpackChunkName: "social-user" */ '../view/pages/classroomInfo/overview/Social.vue'),
        },
      },
    ],
  },
  {
    name: 'student',
    path: '/schoolYears/:schoolYearId/classrooms/:classroomId/students/:studentId',
    props: true,
    redirect: { name: 'student-info' },
    component: () => import(/* webpackChunkName: "classroomInfo" */ '../view/pages/classroomInfo/StudentInfo.vue'),
    children: [
      {
        path: 'info',
        name: 'student-info',
        props: true,
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          descendant: () => import(/* webpackChunkName: "info" */ '../view/pages/classroomInfo/overview/Info.vue'),
        },
      },
      {
        path: 'development',
        name: 'student-development',
        props: true,
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          descendant: () =>
            import(/* webpackChunkName: "info" */ '../view/pages/classroomInfo/overview/DevelopmentInfo.vue'),
        },
      },
      {
        path: 'bodymass',
        name: 'student-bodymass-info',
        props: true,
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          descendant: () =>
            import(/* webpackChunkName: "info" */ '../view/pages/classroomInfo/overview/BodymassInfo.vue'),
        },
      },
      {
        path: 'attendance',
        name: 'student-attendance-info',
        props: true,
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/pages/classroomInfo/Index.vue'),
          descendant: () =>
            import(/* webpackChunkName: "info" */ '../view/pages/classroomInfo/overview/AttendacnceInfo.vue'),
        },
      },
    ],
  },
  {
    name: 'classroom-attendance',
    path: '/schoolYears/:schoolYearId/classrooms/:classroomId/attendance',
    props: true,
    component: () => import(/* webpackChunkName: "Attendance" */ '../view/pages/attendance/Attendance.vue'),
  },
];
