const moment = require('moment');

const dashboardDateReducer = (acc, attendance) => {
  const { date, present, sick, leave, absent } = attendance;

  if (!acc[date]) {
    acc[date] = { present, sick, leave, absent };
  } else {
    acc[date] = {
      present: acc[date].present + present,
      sick: acc[date].sick + sick,
      leave: acc[date].leave + leave,
      absent: acc[date].absent + absent,
    };
  }

  return acc;
};

const dashboardDataMapper = dataItem => {
  const { present, sick, leave, absent } = dataItem;

  return {
    present: present,
    absent: sick + leave + absent,
  };
};

const dashboardLabelMapper = dateString => {
  const date = moment(dateString);
  return date.format('ddd');
};

export default {
  getStudentsBegin(state) {
    state.loading = true;
  },
  fetchingBegin(state) {
    state.loading = true;
  },
  fetchingError(state, err) {
    state.loading = true;
    state.error = err;
  },
  getAttendanceSevenDaysSummarySuccess(state, data) {
    const reducedData = data
      .map(item => item.attendances)
      .flat()
      .reduce(dashboardDateReducer, {});
    const numberData = Object.values(reducedData).map(dashboardDataMapper);
    const labels = Object.keys(reducedData).map(dashboardLabelMapper);
    const present = numberData.map(data => data.present);
    const absent = numberData.map(data => data.absent);
    state.dashboardData = { labels, present, absent };
    console.log(state.dashboardData);
  },
  createAttendanceStudentBegin(state, data) {
    state.loading = false;
    state.data = data;
  },
  createAttendanceStudentSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  createAttendanceStudentError(state) {
    state.loading = true;
  },

  getAttendanceStudentBegin(state) {
    state.loading = true;
  },
  getAttendanceStudentSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  getAttendanceStudentError(state) {
    state.loading = true;
  },
};
