export default {
  getBodymassRecordBegin(state) {
    state.loading = true;
  },

  getBodymassRecordSuccess(state, data) {
    state.loading = false;
    state.currentRecords = data;
  },

  getBodymassRecordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  updateBodymassRecordBegin(state) {
    state.loading = true;
  },

  updateBodymassRecordSuccess(state) {
    state.loading = false;
  },

  updateBodymassRecordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  clearBodymassRecord(state) {
    state.currentRecords = null;
  },
};
