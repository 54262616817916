const routes = [
  {
    path: '/wizard',
    name: 'wizard',
    component: () => import(/* webpackChunkName: "wizard" */ '@/view/wizards/Wizards.vue'),
    children: [
      // {
      //   name: 'wizard1',
      //   path: 'wizard1',
      //   components: {
      //     default: () => import(/* webpackChunkName: "wizard" */ '@/view/wizards/Wizards.vue'),
      //     child: () => import(/* webpackChunkName: "wizard1" */ '@/view/wizards/overview/WizardsOne.vue'),
      //   },
      // },
      // {
      //   name: 'wizard2',
      //   path: 'wizard2',
      //   components: {
      //     default: () => import(/* webpackChunkName: "wizard" */ '@/view/wizards/Wizards.vue'),
      //     child: () => import(/* webpackChunkName: "wizard2" */ '@/view/wizards/overview/WizardsTwo.vue'),
      //   },
      // },
      // {
      //   name: 'wizard3',
      //   path: 'wizard3',
      //   components: {
      //     default: () => import(/* webpackChunkName: "wizard" */ '@/view/wizards/Wizards.vue'),
      //     child: () => import(/* webpackChunkName: "wizard3" */ '@/view/wizards/overview/WizardsThree.vue'),
      //   },
      // },
      // {
      //   name: 'wizard4',
      //   path: 'wizard4',
      //   components: {
      //     default: () => import(/* webpackChunkName: "wizard" */ '@/view/wizards/Wizards.vue'),
      //     child: () => import(/* webpackChunkName: "wizard4" */ '@/view/wizards/overview/WizardsFour.vue'),
      //   },
      // },
      // {
      //   name: 'wizard5',
      //   path: 'wizard5',
      //   components: {
      //     default: () => import(/* webpackChunkName: "wizard" */ '@/view/wizards/Wizards.vue'),
      //     child: () => import(/* webpackChunkName: "wizard5" */ '@/view/wizards/overview/WizardsFive.vue'),
      //   },
      // },
      // {
      //   name: 'wizard6',
      //   path: 'wizard6',
      //   components: {
      //     default: () => import(/* webpackChunkName: "wizard" */ '@/view/wizards/Wizards.vue'),
      //     child: () => import(/* webpackChunkName: "wizard6" */ '@/view/wizards/overview/WizardsSix.vue'),
      //   },
      // },
      {
        name: 'wizard-starter',
        path: 'starter',
        components: {
          default: () => import(/* webpackChunkName: "wizard" */ '@/view/wizards/Wizards.vue'),
          child: () => import(/* webpackChunkName: "starter" */ '@/view/wizards/overview/Starter.vue'),
        },
      },
    ],
  },
];

export default routes;
