export default {
  schoolInfoReadBegin(state) {
    state.loading = true;
  },
  schoolInfoReadSuccess(state, data) {
    state.loading = false;
    state.infos = data;
  },
  schoolInfoReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  schoolInfoUpdateBegin(state) {
    state.loading = true;
  },
  schoolInfoUpdateSuccess(state, data) {
    state.loading = false;
    state.info = data;
  },
  schoolInfoUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
