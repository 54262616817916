const attributeScoreFormatting = data => {
  const scoreReducer = (previousDict, item) => {
    previousDict[`term_${item.termId}`] = item.score;
    return previousDict;
  };

  const attributeReducer = (previousDict, attribute) => {
    const attributeKey = `attribute_${attribute.attributeId}`;
    const scores = attribute.scores.reduce(scoreReducer, {});
    previousDict[attributeKey] = {
      ...scores,
    };
    return previousDict;
  };

  const topicReducer = (previousDict, topic) => {
    const topicKey = `topic_${topic.topicId}`;
    const scores = topic.attributes.reduce(attributeReducer, {});
    previousDict[topicKey] = {
      ...scores,
    };
    return previousDict;
  };

  const studentReducer = (previousDict, studentRecord) => {
    const studentKey = `student_${studentRecord.studentId}`;
    const topicDict = studentRecord.topics.reduce(topicReducer, {});
    previousDict[studentKey] = topicDict;
    return previousDict;
  };

  const records = data.reduce(studentReducer, {});
  return records;
};

export default {
  getAttributeScoresBegin(state) {
    state.loading = true;
  },

  getAttributeScoresSuccess(state, data) {
    state.loading = false;
    state.currentRecords = attributeScoreFormatting(data);
  },

  getAttributeScoresErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  clearAttributeScores(state) {
    state.currentRecords = null;
  },

  getTopicGradeRecordBegin(state) {
    state.loading = true;
  },

  getTopicGradeRecordSuccess(state, data) {
    state.loading = false;
    state.currentTopicGradeRecords = data;
  },

  getTopicGradeRecordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  clearTopicGradeRecord(state) {
    state.currentTopicGradeRecords = null;
  },

  updateTopicGradeRecordBegin(state) {
    state.loading = true;
  },

  updateTopicGradeRecordSuccess(state, data) {
    state.loading = false;
    state.currentTopicGradeRecords = data;
  },

  updateTopicGradeRecordErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
