import axios from 'axios';
import { getItem, setItem } from '../../utility/localStorageControl';
import firebase from 'firebase';
import 'firebase/auth'; // for authentication

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

class DataService {
  static get(path = '', data) {
    const query = new URLSearchParams(data);
    const url = !data ? path : `${path}?${query.toString()}`;

    return client({
      method: 'GET',
      url,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('access_token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  response => response,
  async error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;

    if (!response) {
      return Promise.reject(error);
    }

    if (response.status == 404) {
      return Promise.reject(error);
    }

    if (response.body) {
      const { message } = response.body;

      if (!message.includes('auth/id-token-expired')) {
        return Promise.reject(error);
      }
    }

    //refresh firebase token
    const token = await firebase.auth().currentUser.getIdToken(true);
    if (!token) {
      return Promise.reject(error);
    }
    setItem('access_token', token);

    //error ให้ user ลองใหม่อีกครั้ง
    return Promise.reject(new Error('กรุณาลองใหม่ อีกครั้ง!'));
  },
);
export { DataService };
