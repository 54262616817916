import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import { notification } from 'ant-design-vue';

const updateNotificationSuccess = () => {
  notification.success({
    message: 'บันทึกข้อมูลโรงเรียนเรียบร้อยแล้ว',
  });
};

const updateNotificationError = err => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  infos: null,
  loading: false,
  error: null,
});

const actions = {
  async wizardSetup({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('wizardSetupBegin');
      DataService.post(`/wizard`, data)
        .then(() => {
          commit('wizardSetupSuccess');
          updateNotificationSuccess();
          resolve();
        })
        .catch(err => {
          commit('wizardSetupErr', err);
          updateNotificationError(err);
          reject(err);
        });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
