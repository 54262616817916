import { setItem, getItem } from '@/utility/localStorageControl';

export default {
  wizardSetupBegin(state) {
    state.loading = true;
  },
  wizardSetupSuccess(state) {
    state.loading = false;
    let profile = getItem('profile');
    profile.preferences.wizardRequired = false;
    setItem('profile', profile);
  },
  wizardSetupErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
