import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';

const state = () => ({
  currentRecords: null,
  currentTopicGradeRecords: null,
  loading: false,
  error: null,
});

const actions = {
  getAttributeScores({ commit }, { schoolYearId, classroomId, studentId }) {
    return new Promise((resolve, reject) => {
      commit('clearAttributeScores');
      commit('getAttributeScoresBegin');
      const query = {
        'studentIds[]': studentId,
      };
      DataService.get(`/calendars/${schoolYearId}/classrooms/${classroomId}/grade-records/summary`, query)
        .then(response => {
          const { data } = response;
          commit('getAttributeScoresSuccess', data);

          resolve(data);
        })
        .catch(err => {
          commit('getAttributeScoresErr', err);
          reject(err);
        });
    });
  },

  getTopicGrade({ commit }, { schoolYearId, classroomId, studentId }) {
    commit('clearTopicGradeRecord');
    return new Promise((resolve, reject) => {
      commit('getTopicGradeRecordBegin');

      const query = {
        'studentIds[]': studentId,
      };

      DataService.get(`/calendars/${schoolYearId}/classrooms/${classroomId}/grade-records`, query)
        .then(response => {
          const { data } = response;
          commit('getTopicGradeRecordSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getTopicGradeRecordErr', err);
          reject(err);
        });
    });
  },

  async updateTopicGradeRecords({ commit }, { schoolYearId, classroomId, postData }) {
    return new Promise((resolve, reject) => {
      commit('updateTopicGradeRecordBegin');
      DataService.post(`/calendars/${schoolYearId}/classrooms/${classroomId}/grade-records`, postData)
        .then(response => {
          const { data } = response;
          commit('updateTopicGradeRecordSuccess', data);
          resolve(true);
        })
        .catch(err => {
          commit('updateTopicGradeRecordErr', err);
          reject(err);
        });
    });
  },

  clearAttributeScores({ commit }) {
    commit('clearAttributeScores');
  },

  clearTopicGrades({ commit }) {
    commit('clearTopicGradeRecord');
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
