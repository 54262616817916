import { setItem, getItem, removeItem } from '@/utility/localStorageControl';

export default {
  teamDataBegin(state) {
    state.loading = true;
  },

  teamDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  teamDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  userProfileBegin(state) {
    state.loading = true;
  },

  userProfileSuccess(state, data) {
    setItem('profile', data);
    state.profile = getItem('profile');
  },

  userProfileError(state, error) {
    state.error = error;
  },

  userProfileUpdate(state, data) {
    setItem('profile', data);
    state.profile = getItem('profile');
  },

  userProfileRemove(state) {
    state.profile = null;
    removeItem('profile');
  },

  schoolYearSuccess(state, data) {
    state.schoolYear = data;
  },

  schoolYearError(state, error) {
    state.error = error;
  },

  schoolYearUpdate(state, data) {
    setItem('schoolYear', data);
    state.schoolYear = getItem('schoolYear');
  },

  schoolYearRemove(state) {
    state.schoolYear = null;
    removeItem('schoolYear');
  },
};
