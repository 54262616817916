import mutations from './mutations';
import firebase from 'firebase';
import { notification } from 'ant-design-vue';
import { DataService } from '@/configs/dataService/dataService';
import { setItem } from '@/utility/localStorageControl';

import 'firebase/auth'; // for authentication

const loginNotificationError = err => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: null,
  loading: false,
  isLogout: false,
  isLogin: false,
  error: false,
  isSignUpError: false,
  isSignUpLoading: false,
  uid: false,
  authCode: null,
  lineToken: null,
  lineError: false,
  needSignUp: false,
  needSignIn: false,
  customToken: null,
  accessToken: null,
});

const actions = {
  async lineAuthCallbackBegin({ commit }, authCode) {
    commit('lineCallback', authCode);
  },

  async lineAuthSignIn({ commit }, authCode) {
    try {
      const callbackUrl = `${process.env.VUE_APP_BASE_URL}auth/line-callback`;
      const response = await DataService.post('/auths/line/login', { authCode, callbackUrl });
      const { firebaseCustomToken } = response.data;
      commit('lineIsNeedSignIn', firebaseCustomToken);
    } catch (error) {
      if (error.response.status == 404) {
        commit('lineIsNeedSignUp', error.response.data.token);
      } else {
        loginNotificationError(error.message);
      }
    }
  },

  async lineAuthSignUp({ commit }, newUser) {
    commit('lineSignUpBegin');
    try {
      const { data } = await DataService.post('/auths/line/register', newUser);
      const { firebaseCustomToken } = data;
      commit('lineIsNeedSignIn', firebaseCustomToken);
      await firebase.auth().signInWithCustomToken(firebaseCustomToken);
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const token = await user.getIdToken();
          setItem('access_token', token);
        }
        user = user ? user : {};
        commit('lineSignUpSuccess', user.uid ? user.uid : false);
      });
    } catch (err) {
      loginNotificationError(err.message);
      commit('lineSignUpErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
