import staticData from '@/demoData/teamData.json';
import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';

const state = () => ({
  data: staticData,
  profile: getItem('profile'),
  loading: false,
  error: null,
});

const getters = {
  userProfile: () => () => {
    return getItem('profile');
  },
};

const actions = {
  async teamGetData({ commit }) {
    try {
      commit('teamDataBegin');
      commit('teamDataSuccess', staticData);
    } catch (err) {
      commit('teamDataErr', err);
    }
  },

  async userGetInfos({ commit }) {
    return new Promise((resolve, reject) => {
      commit('userProfileBegin');
      DataService.get('/me')
        .then(response => {
          const { data } = response;
          commit('userProfileSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('userProfileError', err);
          reject(err);
        });
    });
  },
  async forceUserGetInfos({ commit }) {
    return new Promise((resolve, reject) => {
      commit('userProfileBegin');
      DataService.get('/me')
        .then(response => {
          const { data } = response;
          commit('userProfileSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('userProfileError', err);
          reject(err);
        });
    });
  },

  async userProfileRemove({ commit }) {
    commit('userProfileRemove');
  },
};

export default {
  namespaced: false,
  state,
  actions,
  getters,
  mutations,
};
