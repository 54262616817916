import mutations from './mutations';
import firebase from 'firebase';
import { notification } from 'ant-design-vue';
import { db } from '@/configs/database/firebase';
import { DataService } from '@/configs/dataService/dataService';
import { setItem, removeItem } from '@/utility/localStorageControl';

import 'firebase/auth'; // for authentication

const loginNotificationError = err => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: null,
  loading: false,
  isLogout: false,
  isLogin: false,
  error: false,
  isSignUpError: false,
  isSignUpLoading: false,
  uid: false,
  authCode: null,
  lineToken: null,
  lineError: false,
  lineNeedSignUp: false,
  lineNeedSignIn: false,
  customToken: null,
  accessToken: null,
});

const getters = {
  customToken: state => {
    return state.customToken;
  },
};

const actions = {
  async firebaseAuthLogin({ commit }, { data }) {
    try {
      await commit('firebaseLoginBegin');
      await firebase.auth().signInWithEmailAndPassword(data.username, data.password);

      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const token = await user.getIdToken();
          setItem('access_token', token);
        }
        user = user ? user : {};
        commit('firebaseLoginSuccess', user.uid ? user.uid : false);
      });
    } catch (err) {
      loginNotificationError(err.message);
      await commit('firebaseLoginErr', err);
    }
  },

  async firebaseAuthLoginWithGoogle({ commit }) {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await commit('firebaseLoginBegin');
      const result = await firebase.auth().signInWithPopup(provider);
      await commit('firebaseLoginSuccess', result);
    } catch (err) {
      await commit('firebaseLoginErr', err);
    }
  },

  async firebaseAuthLoginWithFacebook({ commit }) {
    const provider = new firebase.auth.FacebookAuthProvider();
    try {
      await commit('firebaseLoginBegin');
      const result = await firebase.auth().signInWithPopup(provider);
      await commit('firebaseLoginSuccess', result);
    } catch (err) {
      await commit('firebaseLoginErr', err);
    }
  },

  async firebaseAuthLoginWithLine({ commit }, customToken) {
    try {
      await commit('firebaseLoginBegin');
      await firebase.auth().signInWithCustomToken(customToken);
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const token = await user.getIdToken();
          setItem('access_token', token);
        }
        user = user ? user : {};
        commit('firebaseLoginSuccess', user.uid ? user.uid : false);
      });
    } catch (error) {
      console.log({ error });
      await commit('firebaseLoginErr', error);
    }
  },

  firebaseAuthGetUid({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit('firebaseLoginBegin');
        firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            const token = await user.getIdToken();
            setItem('access_token', token);
          }
          user = user ? user : {};
          commit('firebaseLoginSuccess', user.uid ? user.uid : false);
          resolve(user);
        });
      } catch (err) {
        commit('firebaseLoginErr', err);
        reject(err);
      }
    });
  },

  async firebaseAuthLogout({ commit }, logOutRoot) {
    try {
      await commit('firebaseLogOutBegin');
      await firebase.auth().signOut();
      removeItem('access_token');
      await commit('firebaseLogOutSuccess', false);
      await logOutRoot();
    } catch (err) {
      await commit('firebaseLogOutErr', err);
    }
  },

  async firebaseAuthSignUp({ commit }, newUser) {
    try {
      await commit('firebaseSignUpBegin');
      const resp = await firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password);
      await db
        .collection('users')
        .doc(resp.user.uid)
        .set({
          ...newUser,
        });
      await commit('firebaseSignUpSuccess', resp.user.uid);
    } catch (err) {
      loginNotificationError(err.message);
      await commit('firebaseSignUpErr', err);
    }
  },

  async firebaseAuthSignUpWithLine({ commit }, newUser) {
    try {
      await commit('firebaseSignUpBegin');
      const { data: firebaseCustomToken } = await DataService.post('/auths/line/register', newUser);
      await commit('firebaseSignUpWithLineSuccess', firebaseCustomToken);
    } catch (error) {
      loginNotificationError(error.message);
      await commit('firebaseSignUpWithLineErr', error);
    }
  },

  async lineAuthLogin({ commit }, authCode) {
    try {
      const callbackUrl = `${process.env.VUE_APP_BASE_URL}auth/line-callback`;
      const response = await DataService.post('/auths/line/login', { authCode, callbackUrl });
      const firebaseCustomToken = response.data.firebaseCustomToken;

      commit('lineIsNeedSignIn', firebaseCustomToken);

      await firebase.auth().signInWithCustomToken(firebaseCustomToken);

      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const token = await user.getIdToken();
          setItem('access_token', token);
        }

        user = user ? user : {};
        commit('firebaseLoginSuccess', user.uid ? user.uid : false);
      });
    } catch (error) {
      if (error.response.status == 404) {
        commit('lineIsNeedSignUp', error.response.data.token);
      } else {
        loginNotificationError(error.message);
      }
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
