const conditionReducer = (accumulator, topic) => {
  topic.attributes.forEach(attr => {
    attr.indicators.forEach(indicator => {
      const conditions = indicator.conditions.map(condition => {
        return {
          ...condition,
          indicator: {
            id: indicator.id,
            order: indicator.order,
            title: indicator.title,
          },
          attribute: {
            id: attr.id,
            order: attr.order,
            title: attr.title,
          },
          topic: {
            id: topic.id,
            order: topic.order,
            title: topic.title,
          },
        };
      });
      accumulator = accumulator.concat(conditions);
    });
  });

  return accumulator;
};

export default {
  getStandardYearsBegin(state) {
    state.loading = true;
  },

  getStandardYearsSuccess(state, data) {
    state.loading = false;
    state.standardYears = data;
  },

  getStandardYearsErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  getEvaluateFormBegin(state) {
    state.loading = true;
  },

  getEvaluateFormSuccess(state, data) {
    state.loading = false;
    state.currentForm = data.sort((a, b) => {
      return a.order - b.order;
    });
    state.currentConditions = data.reduce(conditionReducer, []);
  },

  getEvaluateFormErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  getDevelopmentRecordBegin(state) {
    state.loading = true;
  },

  getDevelopmentRecordSuccess(state, data) {
    state.loading = false;
    state.currentRecords = data;
  },

  getDevelopmentRecordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  updateDevelopmentRecordBegin(state) {
    state.loading = true;
  },

  updateDevelopmentRecordSuccess(state) {
    state.loading = false;
  },

  updateDevelopmentRecordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  fetchingBegin(state) {
    state.loading = true;
  },

  fetchingError(state, err) {
    state.loading = false;
    state.error = err;
  },

  getAllTopicListSuccess(state, data) {
    state.loading = false;
    const conditions = data
      .map(data => {
        const target = data.conditions.map(cond => {
          return { ...cond, gradeLevel: data.gradeLevel };
        });
        return target;
      })
      .flat();
    state.conditionLibrary = conditions;
  },

  getSchoolYearEvaluationProgressSuccess(state, data) {
    state.loading = false;
    state.currentSchoolYearProgress = data;
  },

  getClassroomEvaluationProgressSuccess(state, data) {
    state.loading = false;
    state.currentClassroomProgress = data;
  },

  clearDevelopmentRecord(state) {
    state.currentRecords = null;
  },

  removeEvaluateForm(state) {
    state.currentForm = null;
  },
};
