import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
// import { notification } from 'ant-design-vue';

const state = () => ({
  attendanceReport: null,
  developmentReport: null,
  loading: false,
  error: null,
});

// const updateNotificationSuccess = title => {
//   notification.success({
//     message: title,
//   });
// };

// const updateNotificationError = title => {
//   notification.error({
//     message: title,
//   });
// };

const actions = {
  getDevelopmentReport({ commit }, { classroomId }) {
    return new Promise((resolve, reject) => {
      commit('fetchingBegin');
      DataService.get('/print/developments', { classroomId })
        .then(response => {
          const { data } = response;
          commit('getDevelopmentReportSuccess', data);
          // updateNotificationSuccess('โหลดคะแนนพัฒนาการเรียบร้อยแล้ว');
          resolve(data);
        })
        .catch(error => {
          commit('fetchingError', error);
          reject(error);
          // updateNotificationError('พบปัญหาในการอ่านคะแนนพัฒนาการ');
        });
    });
  },
  getAttendanceReport({ commit }, query) {
    return new Promise((resolve, reject) => {
      commit('fetchingBegin');
      DataService.get('/print/attendances', query)
        .then(response => {
          const { data } = response;
          commit('getAttendanceReportSuccess', data);
          // updateNotificationSuccess('โหลดข้อมูลการเข้าเรียนเรียบร้อยแล้ว');
          resolve(data);
        })
        .catch(error => {
          commit('fetchingError', error);
          reject(error);
          // updateNotificationError('พบปัญหาในการอ่านข้อมูลการเข้าเรียน');
        });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
