import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import wizardRoutes from './WizardRoutes';
import authRoutes from './authRoutes';
import printRoutes from './printRoutes';

import store from '@/vuex/store';

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
  {
    name: 'Print',
    path: '/print',
    component: () => import(/* webpackChunkName: "print" */ '@/layout/PrintLayout.vue'),
    children: [...printRoutes],
    meta: { wizard: false },
  },
  {
    name: 'Wizard',
    path: '/wizard',
    component: () => import(/* webpackChunkName: "wizard" */ '@/layout/withAdminLayout.vue'),
    children: [...wizardRoutes],
    meta: { wizard: true },
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.auth.login;
  const goToLineCallback = to.meta.line;
  const goToAuth = to.meta.auth;

  if (goToLineCallback) {
    next();
  } else if (goToAuth && loggedIn) {
    next({ to: '/school/calendar' });
  } else if (!goToAuth && !loggedIn) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
