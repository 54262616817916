import staticData from '@/demoData/schoolYears.json';
import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import moment from 'moment';

const termSort = (a, b) => {
  return a.termNo - b.termNo;
};

const doneSchoolYearFilter = schoolYear => {
  const today = new Date();
  const [, term2] = schoolYear.terms.sort(termSort);
  const endDate = moment(term2.endDate);
  return endDate.isBefore(moment(today));
};

const progressSchoolYearFilter = schoolYear => {
  const today = new Date();
  const [, term2] = schoolYear.terms.sort(termSort);
  const endDate = moment(term2.endDate);
  return endDate.isAfter(moment(today));
};

const sortedByStatus = (a, b) => {
  const statusA = doneSchoolYearFilter(a) ? 'done' : 'progress';
  const statusB = doneSchoolYearFilter(b) ? 'done' : 'progress';
  if (statusA < statusB) {
    return -1;
  }
  if (statusA > statusB) {
    return 1;
  }
  return 0;
};

const sortedByYear = (a, b) => {
  return parseInt(a.year) - parseInt(b.year);
};

const state = () => ({
  data: null,
  singData: null,
  selected: null,
  sortedBy: 'year',
  filteredBy: 'all',
  loading: false,
  error: null,
});

const getters = {
  selectedYear: state => () => {
    return state.selected;
  },
  selectedYearTerms: state => () => {
    return state.selected.terms.sort((a, b) => {
      return a.termNo - b.termNo;
    });
  },
  filteredSchoolYears: state => () => {
    let list = state.data;
    const sortedBy = state.sortedBy;
    const status = state.filteredBy;

    if (!list) {
      return [];
    }

    switch (sortedBy) {
      case 'year':
        list = list.sort(sortedByYear);
        break;
      case 'status':
        list = list.sort(sortedByStatus);
        break;
      default:
        break;
    }

    switch (status) {
      case 'done':
        return list.filter(doneSchoolYearFilter);
      case 'progress':
        return list.filter(progressSchoolYearFilter);
      default:
        return list;
    }
  },
};

const actions = {
  async filterSinglePage({ commit }, paramsId) {
    try {
      commit('singleSchoolYearBegin');
      const data = staticData.filter(schoolYear => {
        return schoolYear.id === parseInt(paramsId, 10);
      });
      commit('singleSchoolYearSuccess', data);
    } catch (err) {
      commit('singleSchoolYearErr', err);
    }
  },
  async filterSchoolYearByStatus({ commit }, status) {
    commit('filterSchoolYearSuccess', status);
  },

  async sortingSchoolYearByCategory({ commit }, sortBy) {
    commit('sortingSchoolYearSuccess', sortBy);
  },

  async getSchoolYearData({ commit, state }) {
    if (state.loading == true) return;
    return new Promise((resolve, reject) => {
      commit('getSchoolYearBegin');
      DataService.get('/calendars')
        .then(response => {
          const { data } = response;
          commit('getSchoolYearSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getSchoolYearErr', err);
          reject(err);
        });
    });
  },

  async forceGetSchoolYearData({ commit, state }) {
    if (state.loading == true) return;
    return new Promise((resolve, reject) => {
      commit('getSchoolYearBegin');
      DataService.get('/calendars')
        .then(response => {
          const { data } = response;
          commit('getSchoolYearSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getSchoolYearErr', err);
          reject(err);
        });
    });
  },

  async postSchoolYearData({ commit }, newSchoolYear) {
    return new Promise((resolve, reject) => {
      commit('getSchoolYearBegin');
      DataService.post('/calendars', newSchoolYear)
        .then(response => {
          const { data } = response;
          commit('postSchoolYearSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('postSchoolYearErr', err);
          reject(err);
        });
    });
  },

  updateSchoolYearData({ commit }, { schoolYearId, data }) {
    return new Promise((resolve, reject) => {
      commit('updateSchoolYearBegin');
      DataService.put(`/calendars/${schoolYearId}`, data)
        .then(response => {
          const { data } = response;
          commit('updateSchoolYearSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('updateSchoolYearError', err);
          reject(err);
        });
    });
  },

  updateHolidaysData({ commit }, { schoolYearId, data }) {
    return new Promise((resolve, reject) => {
      commit('updateHolidaysBegin');
      DataService.post(`/calendars/${schoolYearId}/holidays`, data)
        .then(response => {
          const { data } = response;
          commit('updateHolidaysSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('updateHolidaysError', err);
          reject(err);
        });
    });
  },

  selectSchoolYear({ commit }, selectedSchoolYear) {
    commit('selectSchoolYear', selectedSchoolYear);
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
