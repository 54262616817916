import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import { notification } from 'ant-design-vue';

const state = () => ({
  standardYears: null,
  currentForm: null,
  currentConditions: null,
  currentRecords: null,
  currentSchoolYearProgress: null,
  currentClassroomProgress: null,
  topicLibrary: null,
  conditionLibrary: null,
  loading: false,
  error: null,
});

const updateNotificationSuccess = text => {
  notification.success({
    message: text,
  });
};

const updateNotificationError = err => {
  notification.error({
    message: err,
  });
};

const getters = {
  getCurrentTopics: state => () => {
    return state.currentForm;
  },
  getCurrentRecords: state => () => {
    return state.currentRecords;
  },
  getCurrentConditions: state => () => {
    return state.currentConditions;
  },
  findConditionRecords: state => keyword => {
    return state.currentConditions.filter(condition => condition.title.includes(keyword));
  },
  getWeightConditionId: state => () => {
    if (!state.currentConditions) return null;
    return state.currentConditions.find(condition => condition.title.includes('น้ำหนักตามเกณฑ์')).id;
  },
  getHeightConditionId: state => () => {
    if (!state.currentConditions) return null;
    return state.currentConditions.find(condition => condition.title.includes('ส่วนสูงตามเกณฑ์')).id;
  },
};

const actions = {
  getStandardYearsData({ commit }) {
    return new Promise((resolve, reject) => {
      commit('getStandardYearsBegin');
      DataService.get('/developments/standards/years')
        .then(response => {
          const { data } = response;
          commit('getStandardYearsSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getStandardYearsErr', err);
          reject(err);
        });
    });
  },
  forceGetStandardYearsData({ commit }) {
    return new Promise((resolve, reject) => {
      commit('getStandardYearsBegin');
      DataService.get('/developments/standards/years')
        .then(response => {
          const { data } = response;
          commit('getStandardYearsSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getStandardYearsErr', err);
          reject(err);
        });
    });
  },
  getEvaluateForm({ commit }, { standardYear, gradeLevel }) {
    return new Promise((resolve, reject) => {
      commit('getEvaluateFormBegin');
      DataService.get('/developments/standards', { standardYear, gradeLevel })
        .then(response => {
          const { data } = response;
          commit('getEvaluateFormSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getEvaluateFormErr', err);
          reject(err);
        });
    });
  },

  forceGetEvaluateForm({ commit }, { standardYear, gradeLevel }) {
    return new Promise((resolve, reject) => {
      commit('getEvaluateFormBegin');
      DataService.get('/developments/standards', { standardYear, gradeLevel })
        .then(response => {
          const { data } = response;
          commit('getEvaluateFormSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getEvaluateFormErr', err);
          reject(err);
        });
    });
  },

  getClassroomDevelopmentRecords({ commit }, query) {
    return new Promise((resolve, reject) => {
      commit('getDevelopmentRecordBegin');
      const params = { ...query, sortBy: 'desc' };
      DataService.get('/developments/condition-records', params)
        .then(response => {
          const { data } = response;
          commit('getDevelopmentRecordSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('getDevelopmentRecordErr', err);
          reject(err);
        });
    });
  },

  async updateClassroomDevelopmentRecords({ commit }, postData) {
    return new Promise((resolve, reject) => {
      commit('updateDevelopmentRecordBegin');
      DataService.post('/developments/condition-records', postData)
        .then(() => {
          commit('updateDevelopmentRecordSuccess');
          updateNotificationSuccess('บันทึกคะแนนพัฒนาการเรียบร้อยแล้ว');
          resolve(true);
        })
        .catch(err => {
          commit('updateDevelopmentRecordErr', err);
          updateNotificationError(`บันทึกคะแนนล้มเหลว: ${err.message}`);
          reject(err);
        });
    });
  },

  getClassroomDevelopmentAttributeSummary({ commit }, { classroomId, schoolYearId }) {
    return new Promise((resolve, reject) => {
      commit('getClassroomDevelopmentAttributeSummaryBegin');
      DataService.get('/developments/summary')
        .then(response => {
          const { data } = response;
          commit('getClassroomDevelopmentAttributeSummarySuccess', { classroomId, schoolYearId });
          resolve(data);
        })
        .catch(err => {
          commit('getClassroomDevelopmentAttributeSummaryErr', err);
          reject(err);
        });
    });
  },

  getSchoolYearEvaluationProgress({ commit }, schoolYearId) {
    return new Promise((resolve, reject) => {
      commit('fetchingBegin');
      DataService.get('/developments/condition-records/progress', { schoolYearId })
        .then(response => {
          const { data } = response;
          commit('getSchoolYearEvaluationProgressSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('fetchingError', err);
          reject(err);
        });
    });
  },

  getClassroomEvaluationProgress({ commit }, { classroomId, schoolYearId }) {
    return new Promise((resolve, reject) => {
      commit('fetchingBegin');
      DataService.get('/developments/condition-records/progress', { classroomId, schoolYearId })
        .then(response => {
          const { data } = response;
          commit('getClassroomEvaluationProgressSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('fetchingError', err);
          reject(err);
        });
    });
  },

  getAllTopicList({ commit }, standardYear) {
    return new Promise((resolve, reject) => {
      commit('fetchingBegin');
      DataService.get('/developments/standards/infos', { standardYear, 'fetch[]': 'condition' })
        .then(response => {
          const { data } = response;
          commit('getAllTopicListSuccess', data);
          resolve(true);
        })
        .catch(err => {
          commit('fetchingError', err);
          reject(err);
        });
    });
  },

  clearEvaluateForm({ commit }) {
    commit('removeEvaluateForm');
  },
};

export default {
  namespaced: false,
  state,
  actions,
  getters,
  mutations,
};
