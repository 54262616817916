<template>
  <router-view></router-view>
</template>
<script>
import { theme } from './configs/theme/themeVariables';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'MainComponent',

  async setup() {
    const { state, dispatch } = useStore();
    const { push } = useRouter();

    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = computed(() => state.themeLayout.loading);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const localProfile = computed(() => state.user.profile);
    const isFbAuthLoading = computed(() => state.firebase.loading);
    const isLogin = computed(() => state.auth.login);
    const selectedSchoolYear = computed(() => state.schoolYear.selected);

    try {
      // 1. firebase login อยู่แล้วไหม
      await dispatch('firebaseAuthGetUid');

      // 2. login หรือเปล่า
      if (!isLogin.value) {
        push({ name: 'login' });
      } else {
        // 3. โหลด user infos
        const getInfoPromise = [
          dispatch('userGetInfos'),
          dispatch('schoolInfoGetData'),
          dispatch('getStandardYearsData'),
        ];
        await Promise.all(getInfoPromise);

        // 4. เช็คว่าต้อง wizard ไหม
        if (localProfile.value.preferences.wizardRequired) {
          push({ name: 'wizard-starter' });
        } else {
          await dispatch('getSchoolYearData');
          const classrooms = await dispatch('getAllClassrooms', { schoolYearId: selectedSchoolYear.value.id });
          dispatch('updateStudentTable', classrooms);
        }
      }
    } catch (error) {
      push({ name: 'login' });
    }

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
      isFbAuthLoading,
    };
  },
};
</script>
