import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import { notification } from 'ant-design-vue';

const state = () => ({
  data: [],
  students: [],
  studentIds: null,
  selected: null,
  loading: false,
  error: null,
});

const getters = {
  getStudentById: state => id => {
    return state.data.find(student => student.id == id);
  },
  getStudentsByClassroom: state => classroomId => {
    return state.data
      .filter(student => student.classroomId == classroomId)
      .sort((a, b) => {
        return a.studentNo - b.studentNo;
      });
  },
};

const updateNotificationError = err => {
  console.log(notification);
  console.log(err);
  // notification.error({
  //   message: err,
  // });
};

const actions = {
  async saveStudents({ commit }, students) {
    commit('updateStudents', students);
  },
  async getStudents({ commit }, { schoolYearId, classroomId }) {
    try {
      commit('getStudentsBegin');
      const { data } = await DataService.get(`/calendars/${schoolYearId}/classrooms/${classroomId}/students`);
      const students = data;
      console.log({ data: students });
      commit('getStudentsSuccess', students);
    } catch (error) {
      commit('actionStudentError', error);
      updateNotificationError(error);
    }
  },

  async updateStudents({ commit }, studentInfos) {
    try {
      commit('updateStudents', studentInfos);
    } catch (error) {
      commit('actionStudentError', error);
      updateNotificationError(error);
    }
  },

  async createStudent({ commit }, { student, schoolYearId, classroomId }) {
    try {
      commit('createStudentBegin');
      const { data } = await DataService.post(`/calendars/${schoolYearId}/classrooms/${classroomId}/students`, student);
      console.log({ data: data });
      commit('createStudentSuccess', data);
    } catch (error) {
      commit('actionStudentError', error);
      updateNotificationError(error);
    }
  },

  async bulkCreateStudents({ commit }, { schoolYearId, classroomId, students }) {
    return new Promise((resolve, reject) => {
      commit('createBulkStudentBegin');
      DataService.post(`/calendars/${schoolYearId}/classrooms/${classroomId}/students/bulk`, students)
        .then(response => {
          const { data } = response;
          commit('createBulkStudentSuccess', data);
          resolve(data);
        })
        .catch(err => {
          commit('actionStudentError', err.message);
          updateNotificationError(err);
          reject(err);
        });
    });
  },

  async updateStudent({ commit }, { studentId, student, schoolYearId, classroomId }) {
    try {
      commit('updateStudentBegin');
      const { data } = await DataService.put(
        `/calendars/${schoolYearId}/classrooms/${classroomId}/students/${studentId}`,
        student,
      );
      console.log({ data: data });
      commit('updateStudentSuccess', data);
    } catch (error) {
      commit('actionStudentError', error);
      updateNotificationError(error);
    }
  },

  async deleteStudent({ commit }, { studentId, schoolYearId, classroomId }) {
    try {
      commit('updateStudentBegin');
      const { data } = await DataService.delete(
        `/calendars/${schoolYearId}/classrooms/${classroomId}/students/${studentId}`,
      );
      console.log({ data: data });
      // commit('updateStudentSuccess', data);
      commit('deleteStudentSuccess', studentId);
    } catch (error) {
      commit('actionStudentError', error);
      updateNotificationError(error);
    }
  },

  async getStudentInfo({ commit }, { studentId, schoolYearId, classroomId }) {
    try {
      console.log('studentId', studentId);
      commit('getStudentInfoBegin');
      const { data } = await DataService.get(
        `/calendars/${schoolYearId}/classrooms/${classroomId}/students/${studentId}`,
      );
      console.log({ data: data });
      commit('getStudentInfoSuccess', data);
    } catch (error) {
      commit('actionStudentError', error);
      updateNotificationError(error);
    }
  },
  async getStudentInfoLocal({ commit, state }, studentId) {
    const student = state.data.find(student => {
      return student.id == studentId;
    });
    if (student) {
      commit('getStudentInfoSuccess', student);
    }
  },
  async updateStudentTable({ commit }, classrooms) {
    console.log('# updateStudentTable');
    commit('updateStudentTable', classrooms);
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
