<template>
  <div :style="{ 'padding-left': '30px', 'padding-top': '20px' }">
    <a-breadcrumb :routes="routes">
      <template #itemRender="item">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ item.route.breadcrumbName }}
        </span>
        <router-link v-else :to="`/${item.paths[item.paths.length - 1] ?? ''}`">
          {{ item.route.breadcrumbName }}
        </router-link>
      </template>
    </a-breadcrumb>
  </div>
</template>

<script>
const Breadcrumb = {
  name: 'Breadcrumb',
  props: {
    routes: Array,
  },
  setup() {
    return {};
  },
};

export default Breadcrumb;
</script>
