export default {
  getOpinionRecordBegin(state) {
    state.loading = true;
  },

  getOpinionRecordSuccess(state, data) {
    state.loading = false;
    state.currentRecords = data;
  },

  getOpinionRecordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  updateOpinionRecordBegin(state) {
    state.loading = true;
  },

  updateOpinionRecordSuccess(state) {
    state.loading = false;
  },

  updateOpinionRecordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  clearOpinionRecord(state) {
    state.currentRecords = null;
  },
};
