import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import { notification } from 'ant-design-vue';

const state = () => ({
  currentRecords: [],
  loading: false,
  error: null,
});

const updateNotificationSuccess = message => {
  notification.success({ message });
};

const updateNotificationError = err => {
  notification.error({
    message: err,
  });
};

const actions = {
  getClassroomOpinionRecords({ commit }, { schoolYearId, classroomId, query }) {
    return new Promise((resolve, reject) => {
      commit('getOpinionRecordBegin');
      DataService.get(`/calendars/${schoolYearId}/classrooms/${classroomId}/opinion-records`, query)
        .then(response => {
          const { data } = response;
          commit('getOpinionRecordSuccess', data);
          resolve(data);
          updateNotificationSuccess('โหลดคำแนะนำเรียบร้อยแล้ว');
        })
        .catch(err => {
          commit('getOpinionRecordErr', err);
          updateNotificationError(err);
          reject(err);
        });
    });
  },

  updateClassroomOpinionRecords({ commit }, { schoolYearId, classroomId, schoolTermId, records }) {
    return new Promise((resolve, reject) => {
      commit('updateOpinionRecordBegin');
      DataService.post(`/calendars/${schoolYearId}/classrooms/${classroomId}/opinion-records`, {
        schoolTermId,
        records,
      })
        .then(response => {
          const { data } = response;
          commit('updateOpinionRecordSuccess');
          resolve(data);
          updateNotificationSuccess('บันทึกคำแนะนำเรียบร้อยแล้ว');
        })
        .catch(err => {
          commit('updateOpinionRecordErr', err);
          updateNotificationError(err);
          reject(err);
        });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
