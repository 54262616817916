<template>
  <div v-if="isLoading" class="spin">
    <a-spin />
  </div>
  <ThemeProvider
    v-else
    :theme="{
      ...theme,
      rtl,
      topMenu,
      darkMode,
    }"
  >
    <Suspense>
      <template #default>
        <MainComponent v-if="isLogin" />
        <template v-else>
          <router-view />
        </template>
      </template>
      <template #fallback>
        <div class="spin">
          <a-spin />
        </div>
      </template>
    </Suspense>
  </ThemeProvider>
</template>
<script>
import { ThemeProvider } from 'vue3-styled-components';
import { theme } from './configs/theme/themeVariables';
import { computed } from 'vue';
import { useStore } from 'vuex';
import MainComponent from './MainComponent.vue';

export default {
  name: 'App',
  components: {
    ThemeProvider,
    MainComponent,
  },
  setup() {
    const { state } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = computed(() => state.themeLayout.loading);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const isFbAuthLoading = computed(() => state.firebase.loading);
    const isLogin = computed(() => state.auth.login);

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
      isFbAuthLoading,
      isLogin,
    };
  },
};
</script>
