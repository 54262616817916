<template>
  <sdButton size="small" type="primary" @click="goToClassroomInfos()">
    <sdFeatherIcons type="arrow-left-circle" size="14" />
    <span>กลับไปที่ห้องเรียน</span>
  </sdButton>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  name: 'BackToClassroomButton',
  props: { schoolYearId: Number, classroomId: Number },
  setup(props) {
    const router = useRouter();

    const goToClassroomInfos = () => {
      router.push({
        name: 'classroom-info',
        props: {
          schoolYearId: props.schoolYearId,
          classroomId: props.classroomId,
        },
      });
    };

    return {
      goToClassroomInfos,
    };
  },
};
</script>
