export default {
  lineLoginBegin(state, authCode) {
    state.loading = true;
    state.authCode = authCode;
  },

  lineLoginSuccess(state, uid) {
    state.loading = false;
    state.isLogin = true;
    state.uid = uid;
  },

  lineLoginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  lineSignUpBegin(state) {
    state.loading = true;
  },

  lineSignUpSuccess(state, uid) {
    state.loading = false;
    state.isLogin = true;
    state.uid = uid;
  },

  lineSignUpErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  lineCallback(state, authCode) {
    state.authCode = authCode;
  },

  lineCallbackSuccess(state, lineToken) {
    state.lineToken = lineToken;
    state.lineError = false;
  },

  lineCallbackErr(state) {
    state.lineError = true;
  },

  lineIsNeedSignUp(state, lineToken) {
    state.lineToken = lineToken;
    state.needSignUp = true;
  },

  lineIsNeedSignIn(state, customToken) {
    state.customToken = customToken;
  },

  firebaseTokenSuccess(state, token) {
    state.accessToken = token;
  },
};
