import mutations from './mutations';
import { DataService } from '@/configs/dataService/dataService';
import { notification } from 'ant-design-vue';

const state = () => ({
  data: [],
  dashboardData: null,
  students: [],
  studentIds: null,
  selected: null,
  loading: false,
  error: null,
});

const updateNotificationError = err => {
  console.error(err);
  notification.error({
    message: err,
  });
};

const actions = {
  async updateAttendanceStudent({ commit }, { schoolYearId, classroomId, attendanceInfo, callback }) {
    try {
      commit('createAttendanceStudentBegin');
      const { data } = await DataService.post(
        `/calendars/${schoolYearId}/classrooms/${classroomId}/attendance-records`,
        attendanceInfo,
      );
      console.log({ data: data });
      commit('createAttendanceStudentSuccess', data);
      callback(data);
    } catch (error) {
      commit('createAttendanceStudentError', error);
      updateNotificationError(error);
    }
  },
  async getAttendanceStudent({ commit }, { schoolYearId, classroomId, date, callback }) {
    try {
      commit('getAttendanceStudentBegin');
      const { data } = await DataService.get(
        `/calendars/${schoolYearId}/classrooms/${classroomId}/attendance-records?date=${date}`,
      );
      console.log({ data: data });
      console.log({ date: date });
      commit('getAttendanceStudentSuccess', data);
      callback(data);
    } catch (error) {
      commit('getAttendanceStudentError', error);
      updateNotificationError(error);
    }
  },

  getAttendanceSevenDaysSummary({ commit }, { schoolYearId, startDate, endDate, unit }) {
    return new Promise((resolve, reject) => {
      commit('fetchingBegin');
      DataService.get('/dashboard/attendances', { schoolYearId, startDate, endDate, unit })
        .then(response => {
          const { data } = response;
          commit('getAttendanceSevenDaysSummarySuccess', data);
          resolve(data);
        })
        .catch(error => {
          commit('fetchingError', error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
