import moment from 'moment';
import { isIncludedToday } from '../../../utility/datetimeCalculator.js';
import { setItem, removeItem } from '../../../utility/localStorageControl.js';

const termSort = (a, b) => {
  return a.termNo - b.termNo;
};

const getStatusMessage = schoolYear => {
  const today = moment(new Date());
  const [term1, term2] = schoolYear.terms.sort(termSort);
  const term1Start = moment(term1.startDate);
  const term2Start = moment(term2.startDate);
  const term2End = moment(term2.endDate);
  if (today.isBefore(term1Start)) {
    return 'ยังไม่เปิดปีการศึกษา';
  } else if (today.isAfter(term2End)) {
    return 'จบปีการศึกษาแล้ว';
  } else if (today.isBetween(term1Start, term2Start, '[)')) {
    return 'ภาคเรียนที่ 1';
  } else if (today.isBetween(term2Start, term2End)) {
    return 'ภาคเรียนที่ 2';
  }
  return 'Error';
};

const getRecentYear = schoolYears => {
  if (!schoolYears || schoolYears.length == 0) {
    return null;
  }

  const [recentYear] = schoolYears.filter(schoolYear => {
    const { terms } = schoolYear;
    const [firstTerm, secondTerm] = terms.sort(termSort);
    return isIncludedToday(firstTerm.startDate, secondTerm.endDate);
  });

  if (!recentYear) {
    return schoolYears[schoolYears.length - 1];
  }

  return recentYear;
};

export default {
  singleSchoolYearBegin(state) {
    state.loading = true;
  },

  singleSchoolYearSuccess(state, data) {
    state.loading = false;
    state.singleData = data;
  },

  singleSchoolYearErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  filterSchoolYearBegin(state) {
    state.loading = true;
  },

  filterSchoolYearSuccess(state, data) {
    state.filteredBy = data;
  },

  filterSchoolYearErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sortingSchoolYearBegin(state) {
    state.loading = true;
  },

  sortingSchoolYearSuccess(state, data) {
    state.sortedBy = data;
  },

  sortingSchoolYearErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  getSchoolYearBegin(state) {
    state.loading = true;
  },

  getSchoolYearSuccess(state, data = []) {
    const newData = data.map(schoolYear => {
      return {
        ...schoolYear,
        statusMessage: getStatusMessage(schoolYear),
      };
    });
    state.loading = false;
    state.data = newData;
    state.selected = getRecentYear(newData);
  },

  getSchoolYearErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  updateSchoolYearBegin(state) {
    state.loading = true;
  },

  updateSchoolYearSuccess(state, data) {
    state.loading = false;
    const holidays = JSON.stringify(state.selected.holidays);
    const newSelected = { ...data, statusMessage: getStatusMessage(data), holidays: JSON.parse(holidays) };
    state.selected = newSelected;
  },

  updateSchoolYearError(state, error) {
    state.loading = false;
    state.error = error;
  },

  updateHolidaysBegin(state) {
    state.loading = true;
  },

  updateHolidaysSuccess(state, data) {
    state.loading = false;
    state.selected.holidays = data;
  },

  updateHolidaysError(state, error) {
    state.loading = false;
    state.error = error;
  },

  postSchoolYearSuccess(state, data) {
    state.loading = false;
    state.data.push(data);
  },

  postSchoolYearErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  selectSchoolYear(state, schoolYear) {
    state.selected = schoolYear;
    setItem('selectedSchoolYear', schoolYear);
  },

  unselectSchoolYear(state) {
    state.selected = null;
    removeItem('selectedSchoolYear');
  },
};
